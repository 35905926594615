* {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}
body {
    overflow-x: hidden;
}
#link {
    color: #2C3E50;
    margin: 0px 50px 0px 45px;
    text-decoration: none;
}
#link::after{
    display: none; 
 }
#link:hover {
    color: #2CA3F5;
    margin: 0px 50px 0px 45px;
    text-decoration: none;
}
#btn-app {
    margin: 0px 50px 0px 45px;
    background-color: #FF9E20;
    border-radius: 100px;
    color: white;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.nowrap {
    white-space: nowrap;
}
.wrap {
    white-space: normal;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-42 {
    font-size: 42px;
}
.fs-22 {
    font-size: 22px;
}
.fs-20 {
    font-size: 20px;
}
.fs-30 {
    font-size: 30px;
}
.white-text {
    color: #F2F2F2;
}
.bg-blue {
    background-color: #2CA3F5;
}
.text-grey {
    color: #535D63;
}
.bg-milky {
    background-color: #FAFAFA;
}
.logoGooglePlay {
    width: 30%;
    height: auto;
    margin-left: 43px;
}
.logoGooglePlayBanner {
    width: 13%;
    height: auto;
}
.bg-footer {
    width: 100%;
    height: 80px;
    padding-top: 28px;
}
.logo {
    margin: 10px 60px 0px 70px;
    width: 140px;
    height: 65px;
}
.isActive {
    color: #2CA3F5;
    margin: 8px 50px 0px 45px;
    text-decoration: none;
}
.notActive {
    color: #2C3E50;
    margin: 8px 50px 0px 45px;
    text-decoration: none;
}
#dropdown-item {
    width: 350px;
}
.notActive:hover {
    color: #2CA3F5;
    margin: 8px 50px 0px 45px;
    text-decoration: none;
}
.gradient {
    background: linear-gradient(90deg, #2CA3F5 0%, #0378CA 100%);
    width: auto;
    height: 128px;
    padding-top: 54px;
}
.gradient-policy {
    background: linear-gradient(90deg, #2CA3F5 0%, #0378CA 100%);
    width: auto;
    height: 128px;
}
.banner {
    background-image: url('../images/HomeBanner.svg');
    width: auto;
    height: 460px;
    padding: 105px 0px 0px 160px;
}
.text-decoration {
    text-decoration: none;
}
.pointer-events {
    pointer-events: none;
}
.content1 {
    background-image: url('../images/Content1.svg');
    /* width: 1216px; */
    /* width: 99%; */
    height: 647px;
}
.color-blue {
    color: #0378CA;
}
.content1 h6 {
    padding-top: 75px;
}
.icons {
    width: 90px;
    height: auto;
    margin-left: 70px;
    margin-top: 30px;
}
#row-content1 {
    margin-top: 100px;
}
.content2 {
    background-image: url('../images/Content2.svg');
    padding-bottom: 86px;
    background-repeat: no-repeat;
}
.blue-border {
    background: #FFFFFF;
    border: 2px solid #99D5FF;
    box-sizing: border-box;
    border-radius: 20px;
}
.dark-text {
    color: #2C3E50;
}
.red-text {
    color: #F43636;
}
.content2-box1 {
    margin-left: 11%;
    margin-top: 9.4%;
    padding-right: 4%;
}
.content2-box3 {
    margin-left: 21%;
    padding-right: 4%;
    margin-top: 5%;
}
#content2-box2 {
    margin-top: 30%;
}
#content2-box4 {
    margin-top: 55%;
}
.lh-160 {
    line-height: 160%;
}
.lh-130 {
    line-height: 130%;
}
.lh-17 {
    line-height: 17px;
}
.lh-20 {
    line-height: 20px;
}
.lh-10 {
    line-height: 10px;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
#rounded {
    width: 40px;
    height: 40px;
    line-height: 30px;
    border-radius: 50%;
    border: 5px solid #99D5FF;
    font-size: 18px;
    /* color: #fff; */
    text-align: center;
    background: #FFFFFF;
}
#rounded-on  {
    width: 40px;
    height: 40px;
    line-height: 30px;
    border-radius: 50%;
    border: 5px solid #2CA3F5;
    font-size: 18px;
    /* color: #fff; */
    text-align: center;
    background: #FFFFFF;
}
#removePadding {
    padding-left: 0;
    padding-right: 0;
}
.vl {
    border-right: 3px solid #2CA3F5;
    height: 25px;
    margin-right: 38px;
    margin-top: 9px;
}
#backgroundRounded {
    background: #DEF0FC;
    border-radius: 10px;
    padding-right: 30px;
    padding-top: 12px;
    height: 62px;
    cursor: pointer;
}
#backgroundRounded-off {
    color: #ABB2B9;
    padding-right: 30px;
    padding-top: 12px;
    height: 62px;
    cursor: pointer;
}
.img-content3 {
    width: auto;
    height: 380px;
    margin-top: 12px;
}
.pointer {
    cursor: pointer;
}
#box-content4 {
    margin-left: 18%;
    margin-right: 11%;
}
.dropdown-text {
    margin-right: 0;
}
.blue-line {
    border: 1px solid #99D5FF;
    margin-top: 50px;
    margin-bottom: 56px;
}
#tab {
    tab-size: 2;
}
.logoAbout {
    width: 290px;
    height: auto;
}
#description {
    margin-left: 7%;
    margin-right: 7%;
}
#team {
    background-image: url('../images/Bg-team.svg');
}
#text-team {
    padding-top: 14%;
}
#team-img {
    width: 120px;
    height: 120px;
}
#team .card {
    border: 1px solid #99D5FF;
    box-sizing: border-box;
    border-radius: 20px;
    width: 288px;
    height: 480px;
}
.button-next {
    margin-left: 52%;
}
.button-prev {
    margin-right: 52%;
    transform: scaleX(-1);
}
.btn-rotate {
    transform: scaleX(-1);
}
.logoProduct {
    width: 64px;
    margin-right: 10px;
}
.bannerApp {
    background-image: url('../images/BannerAppProduct.svg');
    height: 420px;
}
.imgAppProduct {
    margin-top: 17%;
    margin-left: 47%;
}
#textAppProduct {
    margin-top: 103px;
}
.btnAppEureka {
    background: #FFFFFF;
    border: 1px solid #FF9E20;
    box-sizing: border-box;
    border-radius: 100px;
}
.btnAppEurekaFill {
    background: #FF9E20;
    border: 1px solid #FF9E20;
    box-sizing: border-box;
    border-radius: 100px;
}
.text-orange {
    color: #FF9E20;
}
.produkApp {
    background-image: url('../images/Content1App.svg');
    height: 458px;
    width: auto;
}
.produkApp h6 {
    padding-top: 160px;
}
.produkApp p {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 6%;
}
.produkApp a object {
    margin-top: 32px;
    width: 125px;
}
.produkApp2 {
    background-image: url('../images/Content2App.svg');
    height: 600px;
    width: auto;
}
.produkApp2 h6 {
    margin-left: 110px;
    padding-top: 60px;
}
.imageApp img {
    height: 260px;
    width: 260px;
}
.line-through {
    text-decoration: line-through;
}
.starter-pack {
    background: #FFFFFF;
    box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
    border-radius: 20px;
    width: 319px;
    height: 630px;
    padding-top: 42px;
    padding-bottom: 20px;
    padding-left: 23px;
    padding-right: 23px;
}
.starter-pack button {
    width: 100%;
}
.super-pack {
    background: #FFFFFF;
    border: 1px solid #FF9E20;
    box-sizing: border-box;
    box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
    border-radius: 20px;
    width: 362px;
    height: 782px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 23px;
    background: linear-gradient(#FF9E20 10%, transparent 10%) no-repeat;
}
.super-pack button {
    width: 100%;
}
.super-pack-padding {
    padding-left: 23px;
}
.basic-pack {
    background: #FFFFFF;
    box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
    border-radius: 20px;
    width: 319px;
    height: 693px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 23px;
    padding-right: 23px;
}
.basic-pack button {
    width: 100%;
}
.content4ProductApp {
    background: url('../images/BannerAppProduct2.svg');
    width: 1060px;
    height: 368px;
}
.textBannerProductApp {
    margin-left: 20px;
}
.logoGooglePlayProductApp {
    width: 23%;
    height: auto;
}
.border-white-text {
    text-shadow: -2px -2px 0 #FFFFFF,-2px -1px 0 #FFFFFF,-2px 0px 0 #FFFFFF,-2px 1px 0 #FFFFFF,-2px 2px 0 #FFFFFF,-1px -2px 0 #FFFFFF,-1px -1px 0 #FFFFFF,-1px 0px 0 #FFFFFF,-1px 1px 0 #FFFFFF,-1px 2px 0 #FFFFFF,0px -2px 0 #FFFFFF,0px -1px 0 #FFFFFF,0px 0px 0 #FFFFFF,0px 1px 0 #FFFFFF,0px 2px 0 #FFFFFF,1px -2px 0 #FFFFFF,1px -1px 0 #FFFFFF,1px 0px 0 #FFFFFF,1px 1px 0 #FFFFFF,1px 2px 0 #FFFFFF,2px -2px 0 #FFFFFF,2px -1px 0 #FFFFFF,2px 0px 0 #FFFFFF,2px 1px 0 #FFFFFF,2px 2px 0 #FFFFFF 
}
.bannerProductBook {
    background: url('../images/ProductBookBanner.svg');
    height: 420px;
    padding-top: 65px;
    padding-left: 110px;
}
.bannerProductBook .btnAppEurekaFill {
    width: 333px;
    height: 50px;
    margin-top: 20px;
}
.content1ProductBook {
    background: url('../images/Bg1ProductBook.svg');
    height: 498px;
    padding-top: 160px;
}
.content1ProductBook p {
    margin-left: 110px;
    margin-right: 110px;
    margin-top: 5%;
}
.content2ProductBook {
    background: url('../images/Bg2ProductBook.svg');
    height: 736px;
    padding-top: 60px;
}
.content2ProductBook .col-lg-4 {
    margin-top: 100px;
}
.content4ProductBook {
    background: url('../images/BannerProductBook2.svg');
    width: 1060px;
    height: 413px;
    padding-top: 96px;
    padding-left: 35%;
    background-repeat: no-repeat;
}
.content2Terms {
    margin: 0px 90px 0px 90px;
}
.linkHome:hover {
    color: #259BED;
}
.blue-text {
    color: #259BED;
}
.text-blue {
    color: #2CA3F5;
}
.content3Terms {
    margin: 0px 90px 0px 90px;
}
.inline-block-list li {
    margin-left: -13px;
}
.content1Policy {
    padding-top: 40px;
}
.content2Policy {
    margin: 0px 90px 0px 90px;
}
.content3Policy {
    margin: 0px 90px 0px 90px;
}
.content1Contact {
    margin: 0px 0px 0px 90px;
}
.content2Contact {
    margin: 0px 90px 0px 50px;
}
.form h6 {
    color: #2C3E50;
    font-weight: bold;
    font-size: 14px;
    line-height: 160%;
}
.form {
    padding: 30px 0px 30px 50px;
    border: 1px solid #99D5FF;
    box-sizing: border-box;
    border-radius: 20px;
}
.form input {
    border: 1px solid #99D5FF;
    box-sizing: border-box;
    border-radius: 8px;
    width: 480px;
    height: 36px;
    padding: 10px;
}
.form textarea {
    border: 1px solid #99D5FF;
    box-sizing: border-box;
    border-radius: 8px;
    width: 480px;
    height: 153px;
    padding: 10px;
}
.form button {
    background: #2CA3F5;
    border-radius: 100px;
    width: 480px;
    height: 42px;
    color: #F2F2F2;
    font-size: 14px;
    line-height: 160%;
    font-weight: bold;
    margin-top: 30px;
}
.form button:hover {
    color: #F2F2F2;
}
.leaflet-container {
    width: 380px;
    height: 380px;
}
.margin-icons {
    margin-left: 5px;
    margin-right: 22px;
}

@media only screen and (max-width: 768px) {
    .banner {
        background-image: url('../images/HomeBanner-mobile.svg');
        height: 463px;
        padding: 30px 0px 0px 21px;
    }
    #box-content4 {
        margin: 0;
    }
    .icons {
        width: 80px;
        margin-left: 0;
        margin-top: 30px;
    }
    .margin-icons {
        margin-left: 22px;
        margin-right: 0;
    }
    #row-content1 {
        margin-top: 36px;
    }
    .content1 {
        background-image: url('../images/Content1-mobile.svg');
        height: 666px;
        background-repeat: no-repeat;
    }
    .content1 h6 {
        padding-top: 50px;
    }
    .logoGooglePlay {
        margin-left: 0;
    }
    .copyright {
        font-size: 12px;
    }
    .m-fs-24 {
        font-size: 24px;
    }
    .m-fs-18 {
        font-size: 18px;
    }
    .m-fs-16 {
        font-size: 16px;
    }
    .m-fs-14 {
        font-size: 14px;
    }
    .m-fs-12 {
        font-size: 12px;
    }
    .m-fs-10 {
        font-size: 10px;
    }
    .m-fs-8 {
        font-size: 8px;
    }
    .m-fw-bold {
        font-weight: bold;
    }
    .content2 {
        background-image: url('../images/Content2-mobile.svg');
    }
    .content2-box1 {
        margin-left: 0;
        margin-top: 0;
        padding-right: 0;
    }
    .content2-box1 .box1 {
        margin-left: 20px;
        margin-top: 26px;
        margin-right: 80px;
    }
    #content2-box2 {
        margin-top: 20px;
        margin-left: 80px;
        margin-right: 20px;
    }
    .content2-box3 {
        margin-left: 0;
        padding-right: 0;
        margin-top: 0;
    }
    .content2-box3 .box3 {
        margin-left: 20px;
        margin-right: 100px;
        margin-top: 27px;
    }
    #content2-box4 {
        margin-top: 27px;
        margin-left: 80px;
        margin-right: 20px;
    }
    .img-content3 {
        width: 295;
        height: 295px;
        margin-top: 12px;
    }
    #rounded-on  {
        width: 38px;
        height: 32px;
        background: #2CA3F5;
        line-height: 30px;
        border-radius: 50%;
        border: 5px solid #2CA3F5;
        color: #F2F2F2;
        text-align: center;
        padding-bottom: 30px;
        margin-right: 15px;
    }
    .gradient {
        height: 89px;
        padding-top: 34px;
    }
    .gradient-policy {
        height: 89px;
        padding-top: 34px;
    }
    .logoAbout {
        width: 240px;
        height: auto;
    }
    #home .carousel-indicators [data-bs-target] {
        width: 8px !important;
        height: 8px !important;
        border: 1px solid transparent !important;
        border-radius: 10px;
        background: #2CA3F5 !important;
        margin-left: 10px;
        margin-right: 10px;
    }
    #team {
        background-image: url('../images/Bg-team-mobile.svg');
    }
    #team-img {
        width: 72px;
        height: 72px;
        margin-top: 15px;
    }
    .button-next {
        margin-left: 65%;
    }
    .button-prev {
        margin-right: 65%;
        transform: scaleX(-1);
    }
    #team .card {
        border-radius: 12px;
        width: 170px;
        height: 278px;
    }
    .bannerProductBook {
        background: url('../images/ProductBookBanner-mobile.svg');
        height: 567px;
        padding-top: 30px;
        padding-left: 0;
        text-align: center;
    }
    .bannerProductBook .btnAppEurekaFill {
        width: 325px;
        height: 42px;
        margin-top: 75%;
    }
    .content1ProductBook {
        background: url('../images/Bg1ProductBook-mobile.svg');
        height: 404px;
        padding-top: 80px;
    }
    .content1ProductBook p {
        margin-left: 20px;
        margin-right: 35px;
        margin-top: 8%;
    }
    .content2ProductBook {
        background: url('../images/Bg2ProductBook-mobile.svg');
        height: 524px;
        padding-top: 40px;
    }
    .content2ProductBook h5 {
        margin-top: 20px;
    }
    .content2ProductBook .col-lg-4 {
        margin-top: 250px;
    }
    .content4ProductBook {
        background: url('../images/BannerProductBook2-mobile.svg');
        width: 335px;
        height: 180px;
        padding-top: 52.57px;
        padding-left: 38%;
    }
    .content1Contact {
        margin: 0px 0px 0px 20px;
    }
    .leaflet-container {
        width: 335px;
        height: 335px;
    }
    .content2Contact {
        margin: 0px 20px 0px 20px;
    }
    .form h6 {
        color: #2C3E50;
        font-weight: bold;
        font-size: 12px;
        line-height: 160%;
    }
    .form {
        padding: 30px 0px 30px 30px;
        border-radius: 12px;
    }
    .form input {
        border-radius: 6px;
        width: 275px;
        height: 35px;
        font-size: 12px;
    }
    .form textarea {
        border-radius: 6px;
        width: 275px;
        height: 90px;
        font-size: 12px;
    }
    .form button {
        width: 275px;
        height: 39px;
        color: #F2F2F2;
        font-size: 12px;
    }
    .content1Terms {
        padding-top: 0;
    }
    .content2Terms {
        margin: 0px 20px 0px 20px;
    }
    .content3Terms {
        margin: 0px 20px 0px 20px;
    }
    .content1Policy {
        padding-top: 0;
    }
    .content2Policy {
        margin: 0px 20px 0px 20px;
    }
    .content3Policy {
        margin: 0px 20px 0px 20px;
    }
    .bannerApp {
        background-image: url('../images/BannerAppProduct-mobile.svg');
        height: 563px;
    }
    #textAppProduct {
        margin-top: 30px;
    }
    .produkApp {
        background-image: url('../images/Content1App-mobile.svg');
        background-repeat: no-repeat;
        height: auto;
        width: auto;
    }
    .produkApp h6 {
        padding-top: 70px;
    }
    .produkApp p {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
    }
    .produkApp a object {
        margin-top: 44px;
    }
    .produkApp2 {
        background-image: url('../images/Content2App-mobile.svg');
        background-repeat: no-repeat;
        height: 515px;
    }
    .produkApp2 h6 {
        margin-left: 0px;
        padding-top: 40px;
    }
    #app .carousel-indicators [data-bs-target] {
        width: 8px !important;
        height: 8px !important;
        border: 1px solid transparent !important;
        border-radius: 10px;
        background: #2CA3F5 !important;
        margin-left: 10px;
        margin-right: 10px;
    }
    .content4ProductApp {
        background: url('../images/BannerAppProduct2-mobile.svg');
        background-repeat: no-repeat;
        width: 335px;
        height: 180px;
    }
    .textBannerProductApp {
        margin-left: 120px;
    }
    .logoGooglePlayProductApp {
        width: 100px;
        height: 30px;
        margin-left: 118px;
        margin-top: 20px;
    }
    .starter-pack {
        margin-left: 20px;
        white-space: nowrap;
        width: 215px;
        height: 400px;
        padding-top: 12px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .super-pack {
        white-space: nowrap;
        margin-left: 20px;
        width: 215px;
        height: 510px;
        padding-top: 0;
        padding-right: 0;
        background: linear-gradient(#FF9E20 7%, transparent 7%) no-repeat;
    }
    .super-pack-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
    .basic-pack {
        margin-left: 20px;
        margin-right: 20px;
        width: 215px;
        height: 425px;
        padding-top: 10px;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
    #hide-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    #hide-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* .navbar-light .navbar-toggler-icon {
        background-image: url("../images/App3.svg") !important;
    } */
    .navbar-light .navbar-toggler {
        border: none;
    }
    .logo {
        margin: 0px 40px 0px 0px;
        width: 100px;
        height: auto;
    }
    #btn-app {
        margin: 0px 10px 0px 0px;
        font-size: 12px;
    }
    .isActive {
        margin: 8px 0px 0px 10px;
        font-size: 14px;
        font-weight: bold;
    }
    .notActive {
        margin: 8px 0px 0px 10px;
        font-size: 14px;
        font-weight: bold;
    }
    .notActive:hover {
        margin: 8px 50px 0px 10px;
        font-size: 14px;
        font-weight: bold;
    }
    #link {
        color: #2C3E50;
        margin: 0px 50px 0px 10px;
        font-size: 14px;
        font-weight: bold;
    }
    #link:hover {
        margin: 0px 50px 0px 10px;
        font-size: 14px;
        font-weight: bold;
    }
    .logoProduct {
        width: 50px;
        margin-right: 0;
    }
}